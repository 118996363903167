<template id="chart">
    <div class="">
        <h3 class="text-warning mb-4" v-if="series.length == 0">
            Ni podatkov za prikaz
        </h3>
        <div v-if="series && series.length != 0" class="flex justify-center">
            <VueApexCharts type="bar" :options="chartOptions" :series="series"/>
        </div>
    </div>
</template>
<script>
    import VueApexCharts from 'vue-apexcharts'
    export default {
        components: { VueApexCharts },
        props: {
            options: {
                type: Object
            },
            series: {
                type: Array
            }
        },
        data() {
            return {
                chartOptions: {
                    colors: ['#72a5d8', '#cb64a6', '#bed330', '#7a287d', '#b5d7f4', '#e61e25', '#03A9F4', '#4CAF50', '#F9CE1D', '#FF9800', '#33B2DF', '#546E7A', '#D4526E', '#13D8AA', '#A5978B', '#4ECDC4', '#C7F464', '#81D4FA', '#546E7A', '#FD6A6A', '#2B908F', '#F9A3A4', '#90EE7E', '#FA4443', '#69D2E7'],
                    grid: {
                        borderColor: '#898B8B',
                        strokeDashArray: 2
                    },
                    plotOptions: {
                        bar: {
                            dataLabels: {
                                position: 'top'
                            }
                        },
                        dataLabels: {
                            enabled: true,
                            style: {
                                colors: ['#000']
                            },
                            offsetY: 30
                        }
                    },
                    dataLabels: {
                        enabled: true,
                        formatter(_val, opt) {
                            const series = opt.w.config.series
                            const idx = opt.dataPointIndex
                            const idy = opt.seriesIndex

                            if (idy + 1 !== series.length) {
                                return ''
                            }

                            const total = series.reduce((total, self) => total + self.data[idx].y, 0)
                            return `${total.toLocaleString(undefined, {minimumFractionDigits: 2})  } €`
                        },
                        style: {
                            colors: ['#898B8B'],
                            fontSize: '16px'
                        },
                        offsetY: -40
                    },
                    legend: {
                        show: false
                    },
                    stroke: {
                        show: false
                    },
                    chart: {
                        type: 'bar',
                        stacked: true,
                        toolbar: {
                            show: false
                        },
                        zoom: {
                            enabled: false
                        }
                    },
                    xaxis: {
                        labels: {
                            style: {
                                colors: '#898B8B',
                                fontSize: '16px'
                            }
                        }
                    },
                    yaxis: {
                        labels: {
                            formatter(val) {
                                return `${val.toLocaleString()  } €`
                            },
                            style: {
                                colors: ['#898B8B'],
                                fontSize: '16px'
                            }
                        }
                    },
                    fill: {
                        opacity: 1
                    },
                    tooltip: {
                        marker: {
                            show: true
                        },
                        y: {
                            formatter(val) {
                                return `${val.toLocaleString(undefined, {minimumFractionDigits: 2})  } €`
                            }
                        },
                        x: {
                            show: false
                        }
                    },
                    responsive: [
                        {
                            breakpoint: 850,
                            options: {
                                dataLabels: {
                                    enabled: false
                                },
                                xaxis: {
                                    labels: {
                                        style: {
                                            fontSize: '12px'
                                        }
                                    }
                                },
                                yaxis: {
                                    labels: {
                                        style: {
                                            fontSize: '12px'
                                        }
                                    }
                                }
                            }
                        }
                    ]
                }
            }
        },
        created() {
            this.chartOptions = Object.assign({}, this.chartOptions)
        }
    }
</script>

<style>
</style>