<template>
    <b-overlay :show='showLoader'>
        <b-card class="regular-billing mb-2">
            <h1 class="font-title">Redni obračun</h1>
            <b-row class="m-0 mt-2" align-v="center">
                <b-col cols="12" md="6" xl="3" class="mt-1 mt-xl-0 px-0 px-md-1">
                    <h4 class="mb-0 font">Poišči razdelilinik:</h4>
                </b-col>
                <b-col cols="12" md="6" xl="3" class="mt-1 mt-xl-0 px-0 px-md-1">
                    <Select v-model="filter.date" :options="dates" placeholder="Obdobje" @change="loadRazdelilnik"/>
                </b-col>
                <b-col cols="12" md="6" xl="4" class="mt-1 mt-xl-0 px-0 px-md-1">
                    <Select v-model="filter.unit" :options="units" label="naziv" placeholder="Enota" @change="loadRazdelilnik" :customDropdownLabelFn="customLabelFunction" :customSelectedLabelFn="customLabelFunction"/>
                </b-col>
                <b-col cols="12" md="6" xl="2" class="mt-1 mt-xl-0 px-0 px-md-1">
                    <rounded-button>IŠČI <fa icon="search"/></rounded-button>
                </b-col>
            </b-row>
            <template v-if="data && data.id !== $NILObjectID">
                <div class="mt-2" style="background-color: #BFD42E;">
                    <h4 class="m-0 text-white font title-background">Razdelilnik stroškov: {{data.sklic}} - {{dayjs(filter.date).format('MMMM YYYY')}}</h4>
                </div>
                <div>
                    <b-row class="billing ml-0 mt-1">
                        <b-col cols="12" sm="12" md="6" lg="4" class="mt-1 border-separator pb-1 pb-md-0">
                            <b-row>
                                <b-col cols="5" lg="5" >
                                    <h5>Št. Razdelilnika:</h5>
                                </b-col>
                                <b-col cols="7" lg="7" >
                                    <h5 class="billing-second">{{data.sklic}}</h5>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="5" lg="5">
                                    <h5>TRR za plačilo:</h5>
                                </b-col>
                                <b-col cols="7" lg="7">
                                    <h5 class="billing-second">{{data.trr_terjatve}}</h5>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="5" lg="5">
                                    <h5>Šifra plačnika:</h5>
                                </b-col>
                                <b-col cols="7" lg="7">
                                    <h5 class="billing-second">{{data.partner_sifra}} {{data.partner_naziv}}</h5>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="5" lg="5">
                                    <h5>Datum računa:</h5>
                                </b-col>
                                <b-col cols="7" lg="7">
                                    <h5 class="billing-second">{{dayjs(data.datum_dokumenta).format("DD.MM.YYYY")}}</h5>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="5" lg="5">
                                    <h5>Ref. za plačilo:</h5>
                                </b-col>
                                <b-col cols="7" lg="7">
                                    <h5 class="billing-second">{{data.sklic}}</h5>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="5" lg="5">
                                    <h5>DŠ/ID:</h5>
                                </b-col>
                                <b-col cols="7" lg="7">
                                    <h5 class="billing-second">{{data.partner_davcna}}</h5>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="5" lg="5">
                                    <h5> Datum valute:</h5>
                                </b-col>
                                <b-col cols="7" lg="7">
                                    <h5 class="billing-second">{{dayjs(data.datum_valute).format("DD.MM.YYYY")}}</h5>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="5" lg="5">
                                    <h5>Obračun:</h5>
                                </b-col>
                                <b-col cols="7" lg="7">
                                    <h5 class="billing-second">{{data.obracun_mesec}}/{{data.obracun_leto}}</h5>
                                </b-col>
                            </b-row>
                        </b-col>
                        <b-col cols="12" sm="12" md="6" lg="4" class="mt-1 border-separator pb-1 pb-md-0">
                            <b-row>
                                <b-col cols="5" lg="4">
                                    <h5>Objekt:</h5>
                                </b-col>
                                <b-col cols="7" lg="8">
                                    <h5 class="billing-second">{{data.objekt_sifra}}</h5>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="5" lg="4">
                                    <h5>Vhod:</h5>
                                </b-col>
                                <b-col cols="7" lg="8">
                                    <h5 class="billing-second">{{data.vhod_sifra}}</h5>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="5" lg="4">
                                    <h5>Enota:</h5>
                                </b-col>
                                <b-col cols="7" lg="8">
                                    <h5 class="billing-second">{{data.enota_sifra}}</h5>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="5" lg="4">
                                    <h5>Naziv:</h5>
                                </b-col>
                                <b-col cols="7" lg="8">
                                    <h5 class="billing-second">{{data.naziv}}</h5>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="5" lg="4">
                                    <h5>Geo. podatki:</h5>
                                </b-col>
                                <b-col cols="7" lg="8">
                                    <h5 class="billing-second">/</h5>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="5" lg="4">
                                    <h5>Kat. občina:</h5>
                                </b-col>
                                <b-col cols="7" lg="8">
                                    <h5 class="billing-second">{{data.katastrska_obcina}}</h5>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="5" lg="4">
                                    <h5>Št. stavbe:</h5>
                                </b-col>
                                <b-col cols="7" lg="8">
                                    <h5 class="billing-second">{{data.stev_stavbe}}</h5>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="5" lg="4">
                                    <h5>Solast.del.:</h5>
                                </b-col>
                                <b-col cols="7" lg="8">
                                    <h5 class="billing-second">{{data.solastniski_delez.toFixed(2)}}</h5>
                                </b-col>
                            </b-row>
                        </b-col>
                        <b-col cols="12" sm="12" md="6" lg="4" class="mt-1">
                            <div>
                                <b-row>
                                    <b-col cols="5" lg="5">
                                        <h5>Velikost bruto:</h5>
                                    </b-col>
                                    <b-col cols="7" lg="7">
                                        <h5 class="billing-second">{{data.velikost_bruto.toFixed(2)}}</h5>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="5" lg="5">
                                        <h5>Velikost neto:</h5>
                                    </b-col>
                                    <b-col cols="7" lg="7">
                                        <h5 class="billing-second">{{data.velikost_neto.toFixed(2)}}</h5>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="5" lg="5">
                                        <h5>Št. oseb:</h5>
                                    </b-col>
                                    <b-col cols="7" lg="7">
                                        <h5 class="billing-second">{{data.stev_oseb}}</h5>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="5" lg="5">
                                        <h5>Št. živali:</h5>
                                    </b-col>
                                    <b-col cols="7" lg="7">
                                        <h5 class="billing-second">{{data.stev_zivali}}</h5>
                                    </b-col>
                                </b-row>
                            </div>
                            <div style="height: 50px;">

                            </div>
                            <div class="pr-1" style="position: absolute; bottom: 0px; right: 0px;">
                                <rounded-button :disabled="filter.date === ''" @click="downloadZipFakture" class="d-flex align-items-center">
                                    <fa class="mr-1" icon="download"/>
                                    PRENESI FAKTURE
                                    <b-spinner v-if="loadingFakture" class="ml-1" style="height: 15px; width: 15px;"/>
                                </rounded-button>
                            </div>
                        </b-col>
                    </b-row>
                </div>
            </template>
            <template v-else>
                <div class="mt-2" style="background-color: #BFD42E;">
                    <h4 class="m-0 text-white font title-background">Za izbrane filtre ni bilo mogoče najti podatkov</h4>
                </div>
            </template>
        </b-card>
        <b-card class="regular-billing mb-2" v-if="data && data.id !== $NILObjectID">
            <!-- <b-card class="mt-2" style="background-color: #E6E6E6; border-radius: 0; box-shadow: none;"> -->
            <h3 class="font-title">Grafični prikaz</h3>

            <b-row>

                <template v-if="series && series.length !== 0">
                    <b-col cols="12" md="6" lg="4" class="d-flex justify-content-center">
                        <chart :options="chartOptions" :series="series"/>
                    </b-col>
                    <b-col cols="12" md="6" lg="8" class="pr-5 d-flex align-items-center">
                        <div>
                            <b-row>
                                <b-col cols="12" v-for="(item, index) of series" :key="'cost_'+index">
                                    <b-row>
                                        <b-col cols="6" md="6" align="right" class="px-0">
                                            <h2>{{item.toLocaleString(undefined, {minimumFractionDigits: 2})}} €</h2>
                                        </b-col>
                                        <b-col cols="6" md="6" align="left" class="pr-0">
                                            <b-form-group :label="chartOptions.labels[index]">
                                                <b-progress>
                                                    <b-progress-bar :value="(item * 100) / series.reduce((a, b) => a + b, 0)" :style="{ 'background-color': colors[index] }"/>
                                                </b-progress>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>

                                </b-col>
                            </b-row>
                        </div>
                    </b-col>
                </template>

                <template v-else>
                    <b-col class="text-center text-bold">
                        <h3>Ni podatkov za prikaz</h3>
                    </b-col>
                </template>
            </b-row>
        </b-card>
        <b-card class="regular-billing">

            <h3 class="font-title mb-0">Stroškovni graf</h3>
            <div class="mt-1 d-flex justify-content-end">
                <h4>{{ getGrafDateString() }}</h4>
            </div>
            <chart-cost :series="graf"/>

            <!--            <rounded-button :disabled="disableButton" block @click="downloadRazdelilnik">
                PRENESI PDF RAZDELILNIK 
                <fa class="ml-1" icon="download"/>
                <b-spinner class="ml-1" style="height: 15px; width: 15px;" v-if="pfdRazdelilnik" />
            </rounded-button>-->
        </b-card>
    </b-overlay>
</template>

<script>
    import {BRow, BCol, BProgress, BProgressBar, BFormGroup, BCard, BOverlay, BSpinner} from 'bootstrap-vue'
    import chart from '../../Components/Chart'
    import Select from '../../Components/Select'
    import ChartCost from '@/views/Pages/MyPortal/ChartCost'
    import RoundedButton from '@/views/Components/RoundedButton.vue'
    import {FileMimeType} from '@/libs/mimeTypes'

    export default {
        components: {
            BRow,
            BCol,
            BProgress,
            BProgressBar,
            BFormGroup,
            BCard,
            BOverlay,
            ChartCost,
            chart,
            Select,
            RoundedButton,
            BSpinner
        },
        data() {
            return {
                pfdRazdelilnik: false,
                loadingFakture: false,
                months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
                graf: [],
                showLoader: false,
                units: [],
                dates: [],
                filter: { date: '', manager: 'STANINVEST', unit: ''},
                data: null,
                selected: null,
                colors: ['#72a5d8', '#cb64a6', '#bed330', '#7a287d', '#b5d7f4', '#e61e25', '#03A9F4', '#4CAF50', '#F9CE1D', '#FF9800', '#33B2DF', '#546E7A', '#D4526E', '#13D8AA', '#A5978B', '#4ECDC4', '#C7F464', '#81D4FA', '#546E7A', '#FD6A6A', '#2B908F', '#F9A3A4', '#90EE7E', '#FA4443', '#69D2E7'],
                chartOptions: {
                    labels: [],
                    colors: ['#72a5d8', '#cb64a6', '#bed330', '#7a287d', '#b5d7f4', '#e61e25', '#03A9F4', '#4CAF50', '#F9CE1D', '#FF9800', '#33B2DF', '#546E7A', '#D4526E', '#13D8AA', '#A5978B', '#4ECDC4', '#C7F464', '#81D4FA', '#546E7A', '#FD6A6A', '#2B908F', '#F9A3A4', '#90EE7E', '#FA4443', '#69D2E7']
                },
                series: []
            }
        },
        methods: {
            getGrafDateString() {

                const curr_date = this.dayjs(new Date())
                const start_date = curr_date.subtract('6', 'month')

                return `${start_date.format('MMMM YYYY')  } - ${  curr_date.format('MMMM YYYY')}`
            },
            customLabelFunction(unit) {
                return `${unit.naziv} / ${unit.zaporedna}, šifra: ${unit.sifra} (${unit.vrsta === 'L' ? 'Lastnik' : 'Uporabnik'})`            
            },
            expandAdditionalInfo(row) {
                row._showDetails = !row._showDetails
            },
            downloadRazdelilnik() {
                const thisIns = this
                this.pfdRazdelilnik = true
                this.$http({
                    url: `/api/iiportal-user/v1/attachements/razdelilnik/redni_obracun/${this.data.sklic}`, //your url
                    method: 'GET',
                    responseType: 'blob' // important
                })
                    .then((response) => {
                        const url = window.URL.createObjectURL(new Blob([response.data]))
                        const link = document.createElement('a')
                        link.href = url
                        link.setAttribute('download', `Razdelilnik stroškov ${this.data.enota_sifra} ${this.data.obracun_leto}-${this.data.obracun_mesec}.pdf`) //or any other extension
                        document.body.appendChild(link)
                        link.click()
                    })
                    .catch(error => {
                        thisIns.$printError(`Pri nalaganju pdf je prišlo do napake!\n${error.message}`)
                    }).finally(() => {
                        this.pfdRazdelilnik = false
                    })
            },
            downloadZipFakture() {
                const thisIns = this
                this.loadingFakture = true
                this.$http({
                    url: `/api/iiportal-user/v1/attachements/razdelilnik/fakture/${thisIns.filter.date}/${ this.filter.unit.sifra}`, //your url
                    method: 'GET',
                    responseType: 'blob' // important
                })
                    .then((response) => {
                        let fileType = 'zip'
                        fileType = FileMimeType[response.data.type]

                        const url = window.URL.createObjectURL(new Blob([response.data]))
                        const link = document.createElement('a')
                        link.href = url
                        link.setAttribute('download', `Fakture_${thisIns.filter.date}.${fileType}`) //or any other extension
                        document.body.appendChild(link)
                        link.click()
                    })
                    .catch(error => {
                        Promise.all([error.response.data.text()]).then((values) => {
                            if (values.length > 0 && values[0] === 'found 0 files in database') {
                                thisIns.$printWarning('No files for this unit')
                            } else {
                                thisIns.$printError('Pri nalaganju zip je prišlo do napake!')
                            }
                        })

                        
                    }).finally(() => {
                        thisIns.loadingFakture = false
                    })
            },
            async loadGraf() {
                const res = await this.$http.get(`/api/iiportal-user/v1/e_manager/graf/${ this.filter.unit.sifra}`)
                this.graf = res.data.graf
                
            },
            async loadData() {
                this.showLoader = true
                this.$scrollToTop()
                try {
                    await this.loadDates()
                    await this.loadUnits()
                    await this.loadRazdelilnik()
                    await this.loadGraf()
                } catch (error) {
                    this.$printError(`Prišlo je do napake pri nalaganju podatkov\n${error.message}`)
                } finally {
                    this.showLoader = false
                }
            },
            async loadDates() {
                const res = await this.$http.get('/api/iiportal-user/v1/e_manager/date/redni_obracun/')
                this.dates = res.data
                if (this.dates && this.dates.length > 0) this.filter.date = res.data[0]
            },
            async  loadUnits() {
                const res = await this.$http.get('/api/iiportal-user/v1/e_manager/enota')
                
                for (const unit of res.data) {
                    if (this.units.findIndex(u => u.sifra === unit.sifra) === -1) this.units.push(unit)
                }

                this.units.sort((a, b) => { 
                    if ((a.naziv === b.naziv && a.zaporedna < b.zaporedna) || (a.naziv < b.naziv)) return -1
                    else if (a.naziv > b.naziv) return 1
                    else return 0
                })

                if (this.units.length) this.filter.unit = res.data[0]
            },
            async loadRazdelilnik() {
                if (!this.filter.unit || !this.filter.date) return 
                
                if (!this.showLoader) this.showLoader = true
                
                try {
                    const res = await this.$http.get(`/api/iiportal-user/v1/e_manager/razdelilnik/redni_obracun/${this.filter.date}/${this.filter.unit.sifra}`)
                    this.data = res.data
                    this.setCostPieChartData(this.data.list_stroskov_razdelilnika_za_graf)
     
                } catch (error) {
                    this.$printError(`Pri nalaganju podatkov je prišlo do napake!\n${error.message}`)
    
                } finally {
                    this.showLoader = false
                }
            },
            capitalizeFirstLetter(string) {
                if (!string) return ''
                return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
            },
            setCostPieChartData(listOfCosts) {
                this.series = []
                this.chartOptions.labels = []
                if (!listOfCosts || !listOfCosts.length) return

                for (const cost of listOfCosts) {
                    this.series.push(cost.skupajzddv)
                    this.chartOptions.labels.push(this.capitalizeFirstLetter(cost.skupinastroskanaziv))
                }
            }
        },
        computed: {
            disableButton() {
                //disables button if no data is present for the given filter
                if (this.data) {
                    if (this.data.enota_sifra !== '' && this.data.objekt_sifra !== '' && this.data.id !== '000000000000000000000000') return false
                }
                return true
            }
        },
        async mounted() {
            await this.loadData()
        }
    }
</script>

<style>
.regular-billing .row .col-lg-5.col-5 > h5, .regular-billing .row .col-lg-4.col-5 > h5 {
  font-weight: bold !important;
}
.regular-billing .title-background{
    background: #72a5d8 !important;
    padding: 10px 20px 10px 20px;
}
.regular-billing .button-search{
    background: #72a5d8 !important;
}
.regular-billing .button-search:hover{
    box-shadow: none !important;
}
.regular-billing{
    border-radius: 10px !important;
}
.regular-billing .font-title{
    font-weight: bold;
    color: #72a5d8;
    font-size: 2rem;
}
.regular-billing .font {
    font-weight: bold;
}

.regular-billing .border-right-darkgrey{
    border-right: 1px solid #bfbfbf !important;
}
.regular-billing .billing-second{
    color: #5b5b5b !important;
}
.regular-billing .border-separator {
    border-bottom: 1px solid #bfbfbf!important;
    border-right: none !important;
    padding-bottom: 1em;
}

@media only screen and (min-width: 720px) {
    .regular-billing .border-separator{
        border-bottom: none !important;
        border-right: 1px solid #bfbfbf!important;
    }
}
</style>
